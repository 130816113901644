import React, { useEffect, useState } from 'react';

function Certificate({ dataFile }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(`./data/${dataFile}`)
            .then(response => response.json())  // parse the JSON from the body of the response
            .then(json => setData(json))
            .catch(err => console.error('Error loading data:', err));
    }, [dataFile]);  // re-run this effect whenever dataFile changes


    if (!data) {
        return <center><br />Loading...</center>;  // display a loading message until the data is loaded
    }

    return (
        <div>
            <section id="certificate" class="certificate">
                <header>
                    <b>
                        <h1 class="certificate__title">Certificate of Authentication </h1>
                    </b>
                    <b>
                        <h1 id="title" class="certificate__title">{data.title}</h1>
                    </b>
                    <h2 id="artist" class="certificate__artist">{data.artist}</h2>
                </header>
                <figure>
                    <img id="artImage" class="certificate__image" src={data.imageUrl} alt="Art Piece" />
                </figure>
                <div class="row">
                    <div class="column">
                        <h5>Stroke Count</h5>
                        <article id="strokeCount" class="spec">{data.strokeCount}</article>
                        <h5>Number of Colours</h5>
                        <article id="numColours" class="spec">{data.numColours}</article>
                        <h5>Dimension</h5>
                        <article id="dimension" class="spec">{data.dimension}</article>
                        <h5>Painting Robot</h5>
                        <article id="paintedBy" class="spec">{data.paintedBy}</article>
                    </div>
                    <div class="column">
                        <h5>Edition</h5>
                        <article id="collection" class="spec">{data.piece + '/' + data.totalPieces}</article>
                        <h5>Date Manufactured</h5>
                        <article id="dateManufactured" class="spec">{data.dateManufactured}</article>
                        <h5>Date Purchased</h5>
                        <article id="dateBought" class="spec">{data.dateBought}</article>
                        <h5>Material Used</h5>
                        <article id="materialUsed" class="spec">{data.materialUsed}</article>
                    </div>
                </div>
                <br />
                <h5>Art Piece Background</h5>
                <article id="artBackground" class="spec">{data.artBackground}</article>
                <h5>Artist Background</h5>
                <article id="artistBackground" class="spec">{data.artistBackground}</article>
                <br /><br />
                <figure class="center">
                    <img id="signature" class="certificate__signature" src={data.signatureUrl} alt="Signature" />
                </figure>
                <div class="center">
                    <div class="row">
                        <div class="column">
                            <button onClick={() => window.open('https://acrylicrobotics.ca/', '_blank')} id="producedBy">Acrylic Robotics</button>
                        </div>
                        <div class="column">
                            <button onClick={() => window.open('https://rosca.store/', '_blank')} id="soldBy">Rosca Store</button>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default Certificate;
