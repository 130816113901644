import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Certificate from "./components/Certificate";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/bCEdxyK" element={<Certificate dataFile="mc-lion1.json" />} />
        <Route path="/8WraJpv" element={<Certificate dataFile="mc-lion2.json" />} />
        <Route path="/29djd8x" element={<Certificate dataFile="mc-lion3.json" />} />
        <Route path="/00poP0a" element={<Certificate dataFile="mc-lion4.json" />} />
        <Route path="/D1o0D2k" element={<Certificate dataFile="mc-lion5.json" />} />
        <Route path="/8dh4do3" element={<Certificate dataFile="mc-lion6.json" />} />
        <Route path="/48Nm8ai" element={<Certificate dataFile="mc-lion7.json" />} />
        <Route path="/j28p3ts" element={<Certificate dataFile="mc-lion8.json" />} />
        <Route path="/m8mEtr8" element={<Certificate dataFile="mc-lion9.json" />} />
        <Route path="/C203dff" element={<Certificate dataFile="mc-lion10.json" />} />
        <Route path="/expo-lion" element={<Certificate dataFile="startup-fest.json" />} />
      </Routes>
    </Router>
  );
}

export default App;
